.sslLogo {
	width: 8rem;
	margin: 1rem;

	cursor: pointer;
}

.sslLogoBox {
	display: flex;
	width: 50px;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

@media (max-width: 576px) {
	.sslLogoBox {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.bbbLogo {
	width: 71px;
	height: 138px;
	overflow: hidden;
}

.bbbLogoImage {
	position: relative;
	width: 84px;
}
.bbbLogoImageTest {
	position: absolute;
	top: 0;
	left: 0;
	width: 84px;
	height: 116px;
	z-index: 9;
}

.bbbLogoImageText {
	position: absolute;
	top: 0;
	left: 72px;
	width: 72px;
	height: 118px;
	border-radius: 5px;
	background-color: #005e83;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	color: #fff;
	font-size: 10px;
	font-weight: 200;
	z-index: 9;
	transition: all 0.3s;

	& > span {
		margin-top: 3px;
	}
}

.bbbLogoImageTextBolt {
	font-weight: 500;
}

.bbbLogoImageTextLink {
	font-weight: 500;
	color: rgb(187, 187, 13);
}

.bbbLogoBOx {
	display: flex;
	width: 100%;
	height: 100%;
}

.rigthSide {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 1rem;
}

@media (max-width: 576px) {
	.rigthSide {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-left: 0;
		margin-top: 1rem;
	}
}

.bbbLogoImage {
	z-index: 10;
}

.activeBBBlogoText {
	left: 0;
	transition: all 0.3s;
}

.BBBlogoRating {
	position: absolute;
	width: 71px;
	bottom: 0;
	color: #005e83;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
}
